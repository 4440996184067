// sidebar
.tekup-blog{
  &-sidebar{
    padding-left: 20px;
    @include max(991px) {
      padding-left: 0;
      margin-top: 40px;
    }
  }
  &-widgets{
    h5{
      margin-bottom: 20px;
    }
    padding: 30px;
    margin-bottom: 24px;
    background-color: var(--light-bg1);
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.tekup-search-box{
  position: relative;
  input{
    padding: 15px!important;
    padding-left: 50px!important;
    font-size: 20px;
    height: 60px;
    border: 1px solid #E7E7E8!important;
    background-color: var(--white-bg);
    &::placeholder{
      color: rgba(13, 14, 29, 0.6);
   }
   &:focus{
      border: 1px solid var(--accent-color)!important;
   }
  }
}

button#tekup-search-btn {
  position: absolute;
  left: 20px;
  top: 14px;
}

.tekup-blog{
  &-categorie{
    ul{
      li{
        padding-bottom: 12px;
        &:last-child{
          padding-bottom: 0;
        }
        a{
          display: flex;
          font-size: 20px;
          transition: all 0.4s;
          justify-content: space-between;
          color: var(--heading-color);
          span{
            font-weight: 500;
          }
          &:hover{
            color: var(--accent-color);
          }
        }
      }
    }
  }
  &-tags{
    ul{
      li{
        margin-right: 4px;
        margin-bottom: 8px;
        display: inline-block;
        a{
          padding: 5px 16px;
          font-size: 18px;
          display: block;
          transition: all 0.4s;
          color: var(--heading-color);
          background-color: var(--white-bg);
          &:hover{
            color: var(--white-color);
            background-color: var(--accent-bg);
          }
        }
      }
    }
  }
  
}

.tekup-recent-post {
  &-item{
    gap: 20px;
    display: grid;
    align-items: center;
    overflow: hidden;
    margin-bottom: 24px;
    grid-template-columns: 96px 1fr;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &-data{
    p{
      font-weight: 600;
      margin-bottom: 5px;
      line-height: 28px;
      color: var(--heading-color);
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span{
      font-size: 18px;
      color: var(--heading-color);
    }
  }
  
}

// blog single

.tekup-single-post{
  &-meta{
    padding-top: 35px;
    padding-bottom: 15px;
    margin-bottom: 24px;
    border-bottom: 1px solid #E7E7E8;
    ul{
      li{
        position: relative;
        padding-left: 28px;
        display: inline-block;
        margin-right: 25px;
        &:last-child{
          margin-right: 0;
        }
        a{
          font-size: 20px;
          color: var(--heading-color);
          i{
            position: absolute;
            left: 0;
            color: var(--accent-color);
          }
        }
      }
    }
  }
}
.tekup-single-thumb{
  padding: 16px 0;
  img{
    margin-bottom: 24px;
  }
}


.tekup-single-post-tag{
  &-wrap{
    margin-top: 40px;
    margin-bottom: 52px;
    @include max(991px) {
      margin: 30px 0;
    }
    .tekup-blog-tags{
      ul{
        li{
          a{
            background-color: var(--light-bg1);
            &:hover{
              background-color: var(--accent-bg);
           }
          }
        }
      }
    }
  }
}

.tekup-post-navigation {
  display: flex;
  padding: 30px 0;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
  border-top: 1px solid #E7E7E8;
  border-bottom: 1px solid #E7E7E8;
}

a.nav-previous, 
a.nav-next {
  max-width: 300px;
  font-size: 20px;
  transition: all 0.4s;
  color: var(--heading-color);
  &:hover{
    color: var(--accent-color);
  }
  h5{
    margin-top: 5px;
    margin-bottom: 0;
  }
}


// comment

.tekup-post-comment {
  padding-top: 60px;
  li{
    margin-bottom: 40px;
  }
  h3{
    margin-bottom: 30px;
    font-size: 36px;
    @include max(767px) {
      font-size: 30px;
    }
  }
  &-wrap{
    display: flex;
  }
  
  &-thumb{
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius:100%;
    margin-right: 20px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-data{
    position: relative;
    strong{
      display: block;
      font-size: 22px;
      line-height: 1;
      margin-bottom: 5px;
    }
    span{
      font-weight: 400;
      font-size: 20px;
      display: block;

    }
  }
  .children{
    padding-left: 85px;
    @include max(767px) {
      padding-left: 0;
    }
  }
}

a.tekup-comment-reply {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.4s;
  color: var(--body-color);
  display: flex;
  align-items: center;
  svg{
    margin-right: 10px;
  }
  &:hover {
    svg{
      path{
        fill: var(--accent-color);
      }
    }
  }
  &:hover{
    color: var(--accent-color);
  }
}

.tekup-comment-box{
  padding-top: 40px;
  @include max(991px) {
    padding-top: 10px;
  }
  h3{
    font-size: 36px;
    margin-bottom: 25px;
    @include max(767px) {
      font-size: 30px;
    }
  }
}
.tekup-comment-form{
  margin-bottom: 24px;
}

.tekup-check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.tekup-check label {
  position: relative;
  cursor: pointer;
  margin-bottom: 40px;
}

.tekup-check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0D0E1D;
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
  margin-top: -5px;
}

.tekup-check input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 6px;
  width: 5px;
  height: 8px;
  border: solid var(--accent-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}