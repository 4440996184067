.tekup-section-padding{
  padding: 130px 0;
  @include max(991px) {
    padding: 100px 0;
  }
  @include max(767px) {
    padding: 80px 0;
  }
}
.tekup-section-padding2{
  padding: 120px 0 106px;
  @include max(991px) {
    padding: 100px 0 76px;
  }
  @include max(767px) {
    padding: 80px 0 56px;
  }
}
.tekup-section-padding3{
  padding: 70px 0;
  @include max(767px) {
    padding: 50px 0;
  }
}
.tekup-section-padding4{
  padding: 130px 0 106px;
  @include max(991px) {
    padding: 100px 0 76px;
  }
  @include max(767px) {
    padding: 80px 0 56px;
  }
}
.tekup-section-padding-top{
  padding: 130px 0 0;
  @include max(991px) {
    padding: 100px 0 0;
  }
  @include max(767px) {
    padding: 80px 0 0;
  }
}

.tekup-section-padding-bottom{
  padding: 0 0 130px;
  @include max(991px) {
    padding: 0 0 100px;
  }
  @include max(767px) {
    padding: 0 0 80px;
  }
}

// end section padding
.tekup-section-title{
  padding-bottom: 50px;
  @include max(991px) {
    text-align: center;
  }
  &.center {
    text-align: center;
    max-width: 620px;
    margin: 0 auto;
    &.large-width{
      max-width: 680px;
    }
  }
  &.light-color{
    h2{
      color: var(--white-color);
    }
  }

  .tekup-title-btn {
    @include max(991px) {
      width: 100%;
      margin-top: 24px;
    }
}

  
}

.tekup-default-content{
  h2, h5{
    margin-bottom: 20px;
  }
  &.ml-60{
    margin-left: 60px;
    @include max(1399px) {
      margin-left: 0;
    }
  }
  &.mr-60{
    margin-right: 60px;
    @include max(1399px) {
      margin-right: 0;
    }
  }
  &.light-color{
    h2, h5, p{
      color: var(--white-color);
    }
  }
}