
header.tekup-header-section {
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  transition: all 0.4s;
}
header.tekup-header-section.sticky-menu{
  box-shadow: 0 4px 80px rgba(0,0,0,.1);
}
// v1
header.tekup-header-section.light-color.sticky-menu{
  background-color: var(--dark-bg);
}
header.tekup-header-section.header-seven.sticky-menu{
  background-color: var(--white-bg);
}



.sticky-menu .tekup-header-top{
  margin-top: -57px;
  opacity: 0;
}

.container-fuild{
  max-width: 1920px;
  margin: 0 auto;
  margin-right: 60px;
  margin-left: 60px;
  @include max(1300px) {
    margin-right: 30px;
    margin-left: 30px;
  }
  @include max(991px) {
    margin-right: 12px;
    margin-left: 12px;
  }
}
.tekup-header{
  &-top{
    padding: 12px 0;
    transition: all 0.4s;
    @include max(991px) {
      display: none;
    }
    &.border_bottom{
      border-bottom: 1px solid #D9D9DB;
    }
    &.border_bottom2{
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    
  }
  &-info{
    &-wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    ul{
      li{
        display: inline-block;
        position: relative;
        padding-left: 30px;
        margin-right: 48px;
        font-size: 18px;
        color: var(--white-color);
        &:last-child{
          margin-right: 0;
        }
        a{
          display: flex;
          align-items: center;
          font-size: 18px;
          color: var(--white-color);
        }
        i{
          position: absolute;
          font-size: 22px;
          left: 0;
        }
      }
    }
    &.dark-color{
      ul{
        li{
          color: var(--heading-color);
          a{
            color: var(--heading-color);
          }
          i{
            color: var(--accent-color);
          }
        }
      }
    }
  }
}

.tekup-header-bottom{
  &.border_bottom{
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  &.border_bottom3{
    border-bottom: 1px solid #D9D9DB;
  }
  &.header-seven{
    padding: 0 35px;
    @include max(991px) {
      padding: 20px;
    }
  }
  @include max(991px) {
    padding: 20px 0;
  }
}
.sticky-menu{
  .border_bottom{
    border-bottom: 1px solid var(--heading-color);
  }
}
.tekup-header-icon {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: 20px;
  @include max(991px) {
    margin-left: 0;
  }
}
.site-header--menu-right{
  .tekup-header-icon{
    margin-left: 50px;
    @include max(991px) {
      margin-left: 0;
    }
  }
}


.site-header .mobile-menu-trigger.light span{
  background-color: var(--white-color);
  &:before, &:after {
    background-color: var(--white-color);
  }
}


.tekup-header-wrap{
  display: flex;
  align-items: center;
}

.tekup-header-search {
  cursor: pointer;
  position: relative;
  &.light-color{
    i{
      color: var(--white-color);
      transition: all 0.4s;
    }
    
  }
  i{
    font-size: 24px;
  }
  &:hover i{
    color: var(--accent-color);
  }
  
  @include max(767px) {
    display: none;
  }
  &-section{
    opacity: 0;
    visibility: hidden;
    position: fixed;
    width: 100%;
    background-color: #fff;
    top: 0;
    z-index: 99;
    padding: 130px 0 80px;
    transition: 0.5s all ease;
    transform: translateY(-100%);
    @include max(991px) {
      padding: 100px 0 80px;
    }
    @include max(767px) {
      padding: 80px 0;
    }
    &.open{
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  &-close{
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    i{
      font-size: 30px;
      color: #6E6E77;
      transition: all 0.4s;
    }
    &:hover i{
      color: var(--accent-color);
    }
  }
  
}

.search-overlay.open {
  opacity: .8;
  z-index: 3;
  visibility: visible;
}
.search-overlay {
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  transition: .5s;
}

// header three
.tekup-header-info-box {
  &-wrap{
    display: flex;
    margin-right: 35px;
    @include max(1099px) {
      margin-right: 15px;
    }
  }
  &-icon {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 12px;
    border-radius: 100px;
    background-color: var(--light-bg1);
    i{
      font-size: 26px;
      color: var(--accent-color);
    }
  }
  &-data{
    h6{
      color: var(--heading-color);
    }
    p{
      font-size: 18px;
      margin-bottom: 5px;
      line-height: 1;
      color: var(--heading-color);
    }
  }
  
}




// sidebar menu

.tekup-header-barger{
  cursor: pointer;
  position: relative;
  width: 60px;
  height: 24px;
  display: flex;
  align-items: center;
  @include max(767px) {
    display: none;
  }
  span {
    height: 2px;
    width: 60px;
    margin: 4px 0;
    display: block;
    position: relative;
    background-color: var(--white-bg);
    &:before {
      content: "";
      top: -8px;
      width: 35px;
      height: 2px;
      transition: all 0.4s;
      position: absolute;
      transform: translateX(15px);
      background-color: var(--white-bg);
    }
    
    &::after {
      content: "";
      bottom: -8px;
      width: 35px;
      height: 2px;
      position: absolute;
      transition: all 0.4s;
      transform: translateX(9px);
      background-color: var(--white-bg);
    }
    
  
  }
  &.dark span{
    background-color: var(--dark-bg);
  }
  &.dark span:before, 
  &.dark span:after {
    background-color: var(--dark-bg);
  }

  &:hover span:before{
    transform: translateX(5px);
  }
  &:hover span::after{
    transform: translateX(15px);
  }
  
}



.tekup-sidemenu-wraper{
  position: relative;
}

.tekup-sidemenu-column {
    width: 440px;
    display: block;
    height: 100%;
    position: fixed;
    z-index: 111;
    padding: 30px;
    margin-right: -440px;
    transition: 0.5s;
    right: 0;
    top: 0;
    padding-top: 70px;
    overflow-y: scroll;
    background-color: var(--white-bg);
    .tekup-contact-info-wrap {
      margin-top: 35px;
      margin-bottom: 35px;
  }
  .tekup-social-icon.tekup-social-icon3{
    margin-bottom: 30px;
  }

}
.tekup-sidemenu-thumb{
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:5px;
  }
}

.tekup-contact-info{
  &-wrap{
    &.wrap2{
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      border-top: 1px solid #E7E7E8;
      @include max(991px) {
        text-align: center;
        justify-content: center
      }
      @include max(575px) {
        gap: 0;
      }
      .tekup-contact-info{
        padding-top: 30px;
        padding-right: 50px;
        border-right: 1px solid #E7E7E8;
        @include max(1399px) {
          padding-right: 30px;
        }
        &:last-child{
          border-right: 0;
          padding-right: 0;
          padding-left: 50px;
          @include max(1399px) {
            padding-left: 30px;
          }
        }
        @include max(575px) {
          padding: 25px!important;
          border-right: 0;
        }
      }
    }
  }
  margin-bottom: 45px;
  i{
    display: block;
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 24px;
    color: var(--accent-color);
  }
  h5{
    margin-bottom: 6px!important;
  }
  p{
    font-size: 20px;
    line-height: 28px;
  }
  a{
    font-size: 20px;
    display: block;
    color: var(--heading-color);
  }
}

.tekup-sidemenu-column.active {
      margin-right: 0px;
      z-index: 9999;
}

.tekup-sidemenu-close {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  i{
    font-size: 30px;
    color: #6E6E77;
    transition: all 0.4s;
  }
  &:hover i{
    color: var(--accent-color);
  }
}
.active .tekup-sidemenu-close{
  opacity: 1;
  visibility: visible;
}


.offcanvas-overlay {
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      position: fixed;
      z-index: 0;
      visibility: hidden;
      opacity: 0;
      transition: .5s;
 }

.offcanvas-overlay.active {
      opacity: .7;
      z-index: 111;
      visibility: visible;
 }

.tekup-sidemenu-logo{
  margin-bottom: 30px;
}

.tekup-sidemenu-body {
  p{
    font-size: 20px;
    line-height: 28px;
  }
}


.tekup-sidemenu-column::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.tekup-sidemenu-column::-webkit-scrollbar{
  width: 5px;
  background-color: #F5F5F5;
}

.tekup-sidemenu-column::-webkit-scrollbar-thumb{
  background-color: #000000;
  border: 2px solid #555555;
}


@media (max-width: 767px) {
.tekup-sidemenu-wraper{
  display: none;
}

}