.tekup-shop{
  &-wrap{
    margin-bottom: 50px;
    &:hover .tekup-shop-btn{
      opacity: 1;
      bottom: 28px;
      visibility: visible;
    }
  }
  &-thumb{
    position: relative;
  }
  &-data{
    padding-top: 20px;
    h5{
      margin-bottom: 5px;
    }
    h6{
      font-size: 20px;
      color: var(--accent-color);
      del{
        font-weight: 400;
        color: #6E6E77;
        margin-left: 8px;
      }
    }
  }
  &-btn{
    bottom: 0;
    left: 28px;
    opacity: 0;
    font-size: 20px;
    padding: 10px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    visibility: hidden;
    transition: all 0.4s;
    display: inline-block;
    width: calc(100% - 56px);
    color: var(--white-color)!important;
    background-color: var(--accent-bg);
  }
  &-badge{
    top: 16px;
    left: 16px;
    font-size: 18px;
    padding: 5px 16px;
    font-weight: 400;
    text-align: center;
    position: absolute;
    transition: all 0.4s;
    display: inline-block;
    color: var(--white-color)!important;
    background-color: var(--accent-bg);
  }
}

// shop details
.tekup-tab-slider {
  margin-right: 40px;
  @include max(991px) {
    margin-right: 0;
  }
}
ul.tekup-tabs-menu {
  display: flex;
  gap: 16px;
  margin-top: 16px;
    li{
      display: inline-block;
      a{
        img{
          width: 130px;
          height: 140px;
          @include max(1199px) {
            width: 100px;
            height: 100%;
          }
        }
      }
    }
  }
.tekup-tabs-container {
  overflow: hidden;
}
.tekup-tabs-wrapper {
  position: relative;
  white-space: nowrap;
  img{
    width: 100%;
  }
}
.tekup-tabs-wrapper > * {
  display: inline-block;
  width: 100%;
  margin-left: -2px;
}

.tekup-details-content{
  margin-left: 50px;
  padding-top: 100px;
  @include max(1199px) {
    margin-left: 30px;
    padding-top: 40px;
  }
  @include max(991px) {
    margin-left: 0;
  }
  h2{
    margin-bottom: 8px;
  }
  h6{
    margin-bottom: 28px;
    color: var(--accent-color);
  }
  p{
    margin-bottom: 40px;
  }

}




.tekup-product{
  &-wrap{
    display: flex;
    gap: 16px;
    margin-bottom: 40px;
  }
  &-number{
    position: relative;
    max-width: 151px;
    input{
      height:60px;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      border:1px solid #E7E7E8!important;
      display: inline-block;
      vertical-align: middle;
      padding: 10px 45px!important;
    }
  }
  &-plus, 
  &-minus{
    right: 20px;
    top: 50%;
    cursor: pointer;
    font-size: 23px;
    position: absolute;
    line-height: 20px;
    transform: translateY(-50%);
  }
  &-minus{
    right: auto;
    left: 20px;
  }
  &-btn{
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    display: block;
    text-align: center;
    padding: 14px;
    transition: all 0.4s;
    color: var(--white-color)!important;
    background-color: var(--accent-bg);
    img{
      margin-left: 10px;
    }
    &:hover{
      background-color: var(--dark-bg);
    }
  }
  &-info{
    h5{
      margin-bottom: 12px;
    }
    ul{
      li{
        span{
          font-weight: 600;
        }
        a{
          color: var(--heading-color);
          transition: all 0.4s;
          &:hover{
            color: var(--accent-color);
          }
        }
      }
    }
  }
}

.tekup-product-tab{
  ul.nav{
    margin-bottom: 24px;
    border-bottom: 1px solid #E7E7E8;
  }
  .nav-pills .nav-link{
    padding: 0 0 20px;
    font-weight: 600;
    font-size: 26px;
    margin-right: 60px;
    color: var(--heading-color);
    background-color: transparent;
    position: relative;
    @include max(767px) {
      margin-right: 30px;
    }
    @include max(575px) {
      margin-right: 20px;
      font-size: 20px;
    }
    &:before {
      content: " ";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: transparent;
    }
    &.active{
      color: var(--accent-color);
      &:before {
        background-color: var(--accent-color);
      }
    }
  }
}

.tekup-related-product-section {
  padding-bottom: 80px;
  @include max(991px) {
    padding-bottom: 50px;
  }
  @include max(767px) {
    padding-bottom: 30px;
  }
}
// End single
.tekup-cart-section {
  padding: 100px 0 50px;
  @include max(991px) {
    padding: 80px 0 30px;
  }
  @include max(767px) {
    padding: 80px 0 20px;
  }
}
.tekup-cart-list{
  @include max(991px) {
    overflow-x: scroll;
  }
  table{
    width: 100%;
    thead{
      tr{
        border-bottom: 1px solid #E7E7E8;
        th{
          padding: 16px 0;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
    tbody{
      tr{
        border-bottom: 1px solid #E7E7E8;
        td{
          padding: 28px;
          font-size: 20px;
          font-weight: 600;
          white-space: nowrap;
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
    }
  }
  .tekup-product-number input {
    height: 52px;
  }
  .tekup-product-number {
    width: 151px;
  }
}

.tekup-cart-thumb {
  display: flex;
  align-items: center;
  @include max(991px) {
    width: 400px;
  }
  i{
    color: #6E6E77;
    cursor: pointer;
  }
  img{
    width: 130px;
    height: 144px;
    margin: 0 24px;
  }
}
.tekup-cart-footer{
  display: flex;
  align-items: center;
  padding: 28px 0;
  justify-content: space-between;
  @include max(767px) {
    display: block;
  }
}
.tekup-cupon-code{
  position: relative;
  width: 415px;
  @include max(767px) {
    width: 100%;
    margin-bottom: 20px;
  }
  input{
    width: 100%;
    padding: 20px 24px!important;
    border: 1px solid #E7E7E8!important;
  }
  img{
    right: 22px;
    top: 16px;
    position: absolute;
  }
}

.tekup-update-cart{
  font-size: 20px;
  font-weight: 500;
  padding: 14px 40px;
  text-align: center;
  display: inline-block;
  transition: all 0.4s;
  color: var(--white-color)!important;
  background-color: var(--dark-bg);
  @include max(767px) {
    width: 100%;
  }
  &:hover {
    background-color: var(--accent-bg);
  }
}

.tekup-cart-total{
  h5{
    margin-bottom: 20px;
  }
  &-item{
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid #E7E7E8;
    p{
      margin: 0;
      width: 150px;
      span{
        font-weight: 600;
        color: var(--accent-color);
      }
    }
  }

}

.tekup-update-cart.blue-btn{
  margin-top: 40px;
  background-color: var(--accent-bg);
  &:hover{
    background-color: var(--dark-bg);
  }
}

// End cart

.tekup-checkout-header {
  
  img{
    margin-right: 10px;
  }
  font-size: 20px;
  a{
    color: var(--accent-color);
  }
  display: flex;
  padding: 14px 28px;
  align-items: center;
  margin-bottom: 60px;
  background-color: var(--light-bg1);
  @include max(991px) {
    margin-bottom: 40px;
  }
}

.tekup-checkout{
  &-form{
    h5{
      margin-bottom: 24px;
    }
  }
  &-field{
    margin-bottom: 24px;
    &:last-child{
      margin-bottom: 0;
    }
    label {
      display: block;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    input, 
    textarea{
      height: 60px;
      padding: 24px!important;
      border: 1px solid #E7E7E8!important;
    }
    textarea{
      height: 148px;
    }
    select{
      height: 60px;
      width: 100%;
      padding: 10px 24px;
      font-size: 20px;
      position: relative;
      color: rgba(13, 14, 29, 0.6);
      border: 1px solid #E7E7E8;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
    
      &:focus-visible{
        outline: none;
      }
    }
    &.dropdown{
      position: relative;
      &:before {
        content: "\ea4e";
        right: 30px;
        top: 54px;
        width: 12px;
        height: 12px;
        z-index: 2;
        font-size: 26px;
        position: absolute;
        font-family: remixicon !important;
      }
     
    }
  }
}

.tekup-checkout-field.checkout-additional-information {
  margin-top: 60px;
  @include max(991px) {
    margin-top: 40px;
  }
}

input.house-number {
  margin-bottom: 12px;
}

.tekup-checkuot-sidebar{
  @include max(991px) {
    margin-top: 40px;
  }
  ul{
    li{
      padding: 24px 0;
      line-height: 24px;
      border-bottom: 1px solid #E7E7E8;
      &:last-child{
        border-bottom: none;
      }
      span{
        float: right;
      }
    }
  }
  h6{
    display: flex;
    font-weight: 40;
    font-size: 20px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 25px;
    img{
      margin-right: 10px;
    }
  }
}

span.total-amount {
  font-size: 26px;
  font-weight: 600;
  color: #0119FF;
}

.tekup-update-cart.blue-btn.w-full{
  width: 100%;
  text-align: center;
  margin-top: 20px;
}