.tekup-main {
  &-form{
    border: 1px solid #E7E7E8;
    padding: 50px;
    @include max(991px) {
      margin-top: 40px;
    }
    @include max(767px) {
      padding: 30px;
    }
    h3{
      margin-bottom: 7px;
    }
  }
  &-field{
    margin-bottom: 24px;
  }
  
}

button#tekup-main-form-btn{
  width: 100%;
  padding: 14px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  background-color: var(--accent-bg);
  transition: all 0.4s;
  &:hover{
    background-color: var(--dark-bg);
  }
  i{
    font-size: 22px;
    margin-left: 10px;
    transition: all 0.4s;
  }
  &:hover i{
    transform: rotate(45deg);
  }
}


// 

#map{
  height: 658px;
  width: 100%;
  @include max(991px) {
    height: 500px;
  }
  @include max(767px) {
    height: 400px;
  }
}