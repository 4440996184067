h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $heading-font;
  color: var(--heading-color);
  font-weight: 600;
  margin: 0;
  letter-spacing: -1px;
}
h1,
.h1 {
  font-size: 100px;
  line-height: 1em;
  margin-bottom: 24px;
  color: var(--white-color);
  @include max(1400px) { 
    font-size: 70px;
  }
  @include max(767px) { 
    font-size: 48px;
    margin-bottom: 15px;
  }
}

h2,
.h2 {
  font-size: 56px;
  line-height: 1.14em; /* 116.667% */
  @include max(991px) { 
    font-size: 50px;
  }
  @include max(767px) { 
    font-size: 40px;
  }
}

h3,
.h3 {
  font-size: 30px;
  line-height: 1.26em;
  @include max(767px) {
    line-height: 1.2em;
  }

}
h4,
.h4 {
  font-size: 30px;
  line-height: 1.26em;
}
h5,
.h5 {
  font-size: 26px;
  line-height: 1.3em;
}
h6,
.h6 {
  font-size: 22px;
}

p{
  margin-bottom: 24px;
  @include max(767px) {
    margin-bottom: 20px;
  }
  &:last-child{
    margin-bottom: 0;
  }
}

// @each $cheadingfont in $headingfontSize {
//   $i: index($headingfontSize, $cheadingfont);
//   h#{$i},
//   .text-h#{$i} {
//     font-size: $cheadingfont;
//     line-height: nth($headingLineheight, $i);
//   }
// }


@for $i from 10 through 40 {
  .f-size-#{$i} {
    font-size: $i * 1px !important;
  }
}

@for $i from 10 through 40 {
  .line-height-#{$i} {
    line-height: $i * 1px !important;
  }
}

.font-bold{
  font-weight: 700;
}
.font-black{
  font-weight: 800;
}
.font-medium{
  font-weight: 500;
}
.font-normal{
  font-weight: 400;
}
.font-semibold{
  font-weight: 600;
}


// @each $currentfont in $bodyfontvariation {
//   $i: index($bodyfontvariation, $currentfont);
//   .body-font-#{$i} {
//     font-size: $currentfont;
//     line-height: nth($lineheightvariation, $i);
//   }
// }

