

.tekup-portfolio{
  &-section{
    z-index: 0;
    position: relative;
    &:before{
      content: "";
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 65%;
      position: absolute;
      background-color: var(--dark-bg);
    }
  }
  &-thumb{
    position: relative;
    overflow: hidden;
    img{
      transition: all 0.4s;
    }
    &:hover img{
      transform: scale(1.1);
    }
    &:before{
      content: "";
      left: 0;
      z-index: 1;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: all 0.4s;
      position: absolute;
      opacity: 0;
      background: linear-gradient(180deg, rgba(1, 25, 255, 0) 0%, #0119FF 100%);
    }
    &:hover .tekup-portfolio-data{
      opacity: 1;
      visibility: visible;
      bottom: 28px;
    }
    &:hover::before{
      opacity: 1;
    }
  }
  &-data{
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    left: 28px;
    z-index: 2;
    padding: 20px 24px;
    width: calc(100% - 56px);
    position: absolute;
    transition: all 0.4s;
    background-color: var(--white-bg);
    h5{
      margin-bottom: 10px;
    }
    p{
      font-size: 20px;
      margin: 0;
      line-height: 20px;
    }
  }
  &-btn{
    top: 30px;
    right: 15px;
    position: absolute;
    i{
      font-size: 30px;
      color: var(--accent-color);
    }
  }

}

// end v1



.tekup-portfolio{
  &-wrap2{
    margin-bottom: 24px;
  }
  &-thumb2{
    position: relative;
    overflow: hidden;
    img{
      transition: all 0.4s;
    }
    &:hover img {
      transform: scale(1.1);
    }
    &:before{
      content: "";
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      transition: all 0.4s;
      position: absolute;
      opacity: 0;
      background: linear-gradient(180deg, rgba(1, 25, 255, 0) 0%, #0119FF 100%);
    }
    &:hover .tekup-portfolio-data2{
      opacity: 1;
      visibility: visible;
      bottom: 28px;
    }
    &:hover::before{
      opacity: 1;
    }
  }
  &-data2{
    left: 28px;
    bottom: 0;
    width: calc(100% - 56px);
    opacity: 0;
    z-index: 2;
    visibility: hidden;
    transition: all 0.4s;
    position: absolute;
    background-color: transparent;
    h5, p{
      color: var(--white-color);
    }
    h5{
      margin-bottom: 10px;
    }
    p{
      font-size: 20px;
      margin: 0;
      line-height: 20px;
    }
  }
  &-btn2{
    top: 10px;
    right: 0;
    position: absolute;
    i{
      font-size: 30px;
      color: var(--white-color);
    }
  }

}
.tekup-center-btn {
  text-align: center;
  margin-top: 28px;
}

// End v2

.tekup-portfolio{
  &-wrap3{
    transition: all 0.4s;
    background-color: var(--white-bg);
    &:hover{
      box-shadow: 0px 16px 50px 0px rgba(13, 14, 29, 0.12);
    }
    &:hover .tekup-portfolio-btn3{
      top: 50%;
      opacity: 1;
      visibility: visible;
    }
    &:hover .tekup-portfolio-thumb3::before{
      opacity: 1;
    }
  }
  &-thumb3{
    position: relative;
    &:before {
      content: "";
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      transition: all 0.4s;
      position: absolute;
      background: rgba(1, 25, 255, 0.7);
    }
  }
  &-data3{
    padding: 24px;
    h3{
      margin-bottom: 5px;
    }
    p{
      font-size: 20px;
    }
  }
  &-btn3{
    left: 50%;
    top: 60%;
    font-size: 30px;
    width: 80px;
    height: 80px;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    color: var(--heading-color)!important;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: var(--white-bg);

  }
}

.slider-three {
  .slick-list.draggable{
    padding-bottom: 55px;
  }
  .slick-dots{
    margin-top: 0;
  }
}

// End v4

.tekup-portfolio{
  &-wrap4{
    margin-bottom: 24px;
  }
  &-thumb4{
    position: relative;
    overflow: hidden;
    img{
      transition: all 0.4s;
    }
    &:hover img{
      transform: scale(1.1);
    }
    &:before{
      content: "";
      left: 0;
      z-index: 1;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: all 0.4s;
      position: absolute;
      opacity: 0;
      background: linear-gradient(180deg, rgba(1, 25, 255, 0) 0%, #0119FF 100%);
    }
    &::after{
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: all 0.4s;
      position: absolute;
      background: linear-gradient(180deg, rgba(13, 14, 29, 0) 0%, #0D0E1D 100%);
    }
    &:hover::before{
      opacity: 1;
    }
    &:hover::after{
      opacity: 0;
    }
    &:hover .tekup-portfolio-btn4{
      opacity: 1;
    }
  }
  &-data4{
    bottom: 28px;
    left: 28px;
    z-index: 1;
    width: calc(100% - 56px);
    position: absolute;
    transition: all 0.4s;
    h5, p{
      color: var(--white-color);
    }
    h5{
      margin-bottom: 10px;
    }
    p{
      font-size: 20px;
      margin: 0;
      line-height: 20px;
    }
  }
  &-btn4{
    top: 10px;
    right: 0;
    opacity: 0;
    transition: all 0.4s;
    position: absolute;
    i{
      font-size: 30px;
      color: var(--white-color);
    }
  }

}

// star portfolio page

.tekup-portfolio-column{
  margin-left: -24px;
}
.tekup-portfolio-column .tekup-portfolio-wrap, 
.tekup-portfolio-column .tekup-portfolio-wrap4 {
  margin-left: 24px;
  margin-bottom: 24px;
}
.tekup-portfolio-menu {
  max-width: 856px;
  margin: 0 auto;
  margin-bottom: 50px;
  border-bottom: 1px solid #E7E7E8;
  @include max(575px) {
    border: 0;
  }
}
.tekup-portfolio-menu  {
 ul{
  display: flex;
  justify-content: space-between;
  @include max(575px) {
    display: block;
  }
  li{
    cursor: pointer;
    font-weight: 600;
    padding-bottom: 24px;
    display: inline-block;
    color: var(--heading-color);
    position: relative;
    @include max(767px) {
      font-size: 20px;
    }
    @include max(575px) {
      display: block;
      text-align: center;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 2px solid #E7E7E8;
    }
    &:before {
      content: "";
      left: 0;
      bottom: -1px;
      width: 0;
      height: 2px;
      transition: all 0.4s;
      position: absolute;
      background-color: var(--accent-color);
    }
    &.active::before{
      width: 100%;
    }
  }
 }
}

.tekup-portfolio-menu ul li:first-child{
  margin-left: 0;
}
.tekup-portfolio-menu ul li:last-child{
  margin-right: 0;
}
.tekup-portfolio-menu ul li.active{
  color: var(--accent-color);
  position: relative;
  transition: all 0.4s;
}

.collection-grid-item-w2 {
  overflow: hidden;
  max-width: 100%;
}

// start portfolio details
.tekup-pd-wrap{
  padding: 50px 0 80px;
  @include max(991px) {
    padding: 40px 0;
  }
  .tekup-icon-list{
    margin-bottom: 40px;
    @include max(991px) {
      margin-bottom: 24px;
    }

  }
}
.tekup-pd-content-item{
  margin-bottom: 40px;
  &:last-child{
    margin-bottom: 0;
  }
  @include max(991px) {
    margin-bottom: 24px;
  }
  img{
    @include max(991px) {
      margin-bottom: 24px;
    }
  }
  h3{
    font-size: 36px;
    margin-bottom: 15px;
    @include max(991px) {
      font-size: 30px;
    }
  }
 
}
.tekup-pd-sidebar{
  padding: 30px;
  margin-left: 30px;
  position: sticky;
  top: 120px;
  background-color: var(--light-bg1);
  @include max(991px) {
    margin-left: 0;
    margin-top: 40px;
    position: static;
  }
  &-item{
    margin-bottom: 22px;
    h5{
      margin-bottom: 20px;
    }
    span{
      font-weight: 600;
      margin-bottom: 5px;
      display: block;
      line-height: 1em;
    }
    p{
      line-height: 22px;
    }
    a{
      color: var(--accent-color);
    }
  }
}