
.tekup-footer{
  &-textarea{
    @include max(1199px) {
      max-width: 400px;
      margin-bottom: 40px;
    }
    img{
      margin-bottom: 30px;
    }
    p{
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 28px;
    }
    &.light-color{
      p{
        color: var(--white-color);
      }
      
    }
  }
  &-title{
    h5{
      margin-bottom: 26px;
      @include max(767px) {
        margin-bottom: 20px;
      }
    }
    p{
      font-size: 20px;
      line-height: 30px;
    }
    &.light-color{
      h5, p{
        color: var(--white-color);
      }
    }
  }
  &-menu{
    @include max(767px) {
      margin-bottom: 40px;
    }
    ul{
      li{
        margin-bottom: 12px;
        &:last-child{
          margin-bottom: 0;
        }
        a{
          font-size: 20px;
          
          color: var(--heading-color);
          position: relative;
          &:before{
            content: " ";
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            position: absolute;
            transition: all 0.4s;
            background: var(--heading-color);
          }
          &:hover:before{
            width: 100%;
          }
        }
      }
    }
    &.light-color{
      ul{
        li{
          a{
            color: var(--white-color);
            &::before{
              background: var(--white-color);
            }
          }
        }
      }
    }
    &.style-two{
      ul{
        li{
          display: inline-block;
          margin-right: 24px;
          margin-bottom: 0;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    &.right{
      text-align: right;
      @include max(767px) {
        text-align: center;
      }
    }
  }
}

.tekup-footer-bottom{
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  @include max(991px) {
    padding: 25px;
  }
  &.center{
    text-align: center;
  }
  &.dark{
    border-top: 1px solid #E7E7E8;
  }
  
}


.tekup-copywright{
   p{
    font-size: 20px;
    line-height: 30px;
   }
   &.light-color{
    p{
      color: var(--white-color);
    }
   }
   @include max(767px) {
    text-align: center;
    margin-bottom: 15px;
   }
}

.extar-margin{
  margin-left: 50px;
  @include max(1199px) {
    margin-left: 0;
  }
}

// end main footer
// start footer v1
.extra-minus-margin{
  margin-top: -100px;
}
.tekup-footer-top.extra-padding{
  padding: 230px 0 130px;
  @include max(991px) {
    padding: 200px 0 100px;
  }
  @include max(991px) {
    padding: 180px 0 80px;
  }
}
.tekup-footer-info{
  ul{
    li{
      padding-left: 30px;
      position: relative;
      margin-bottom: 5px;
      &:last-child{
        margin-bottom: 0;
      }
      a{
        font-size: 20px;
        color: var(--white-color);
        i{
          top: 0;
          left: 0;
          font-size: 22px;
          position: absolute;
        }
      }
    }
  }
  &.dark-color{
    ul{
      li{

        a{
          color: var(--heading-color);
          i{
            color: var(--accent-color);
          }
        }
      }
    }
  }
}

.tekup-infobox{
  &-wrap{
    &.extra-padding{
      padding: 130px 0 56px;
      @include max(991px) {
        padding: 100px 0 56px;
      }
      @include max(767px) {
        padding: 80px 0 56px;
      }
    }
  }
  &-item{
    padding: 30px;
    display: flex;
    gap: 20px;
    margin-bottom: 24px;
    background-color: #1F202E;
    @include max(991px) {
      padding: 25px;
    }
    
  }
  &-icon{
    width: 52px;
    height: 52px;
    display: flex;
    flex-shrink: 0;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-bg);
    i{
      font-size: 24px;
      color: var(--white-color);
    }
  }
  &-data{
    p{
      font-size: 18px;
      margin-bottom: 5px;
      line-height: 1em;
      color: var(--white-color);
    }
    h5{
      margin: 0;
      color: var(--white-color);
    }
    &.dark-color{
      p, h5{
        color: var(--heading-color);
      }
    }
  }
}
