.tekup-service-details{
  &-wrap{
    img{
      margin-bottom: 50px;
    }
  }
  &-item{
    margin-bottom: 45px;
    &:last-child{
      margin-bottom: 0;
    }
    h3{
      font-size: 36px;
      margin-bottom: 15px;
    }
  }
}

.tekup-service{
  &-sidebar{
    margin-left: 30px;
    @include max(1199px) {
      margin-left: 0;
    }
    @include max(991px) {
      margin-top: 40px;
    }
  }
  &-menu{
    ul{
      li{
        margin-bottom: 14px;
        &:last-child{
          margin-bottom: 0;
        }
        a{
          display: flex;
          padding: 14px 30px;
          transition: all 0.4s;
          color: var(--heading-color);
          justify-content: space-between;
          background-color: var(--light-bg1);
          &:hover{
            color: var(--white-color);
            background-color: var(--accent-bg);
          }
        }
      }
    }
  }

  &-contact{
    margin-top: 50px;
    padding: 40px 30px;
    background-size: cover;
    @include max(991px) {
      padding: 30px;
    }
    img{
      margin-bottom: 15px;
    }
    h3{
      font-size: 40px;
      line-height: 46px;
      margin-bottom: 10px;
      color: var(--white-color);
      @include max(991px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
    p{
      font-size: 20px;
      line-height: 30px;
      color: var(--white-color);
      margin-bottom: 80px;
      @include max(991px) {
        margin-bottom: 40px;
      }
    }
  }
  
}
