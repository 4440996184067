

.tekup-pricing{
  &-wrap{
    margin-bottom: 24px;
    padding: 40px 28px;
    background-color: var(--white-bg);
    &.all-border{
      border: 1px solid #E7E7E8;
    }
  }
  &-header{
    margin-bottom: 30px;
    h4{
      margin-bottom: 10px;
    }
  }
  &-price{
    margin-bottom: 20px;
    h2{
      font-size: 80px;
      line-height: 1;
      span{
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
  &-btn{
    width: 100%;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    padding: 13px;
    transition: all 0.4s;
    align-items: center;
    justify-content: center;
    color: var(--heading-color);
    border: 1px solid var(--heading-color);
    &:hover{
      color: var(--white-color);
      background-color: var(--accent-bg);
      border: 1px solid var(--accent-color);
    }
    i{
      margin-left: 10px;
      font-size: 22px;
      transition: all 0.4s;
    }
    &:hover i{
      color: var(--white-color);
      transform: rotate(45deg);
    }
    &.active{
      color: var(--white-color);
      background-color: var(--accent-bg);
      border: 1px solid var(--accent-color);
      &:hover{
        color: var(--white-color);
        background-color: var(--dark-bg);
        border: 1px solid var(--heading-color);
      }
    }
  }
  &-feature{
    margin-top: 40px;
    &.extra-mt{
      margin-bottom: 40px;
      margin-top: 35px;
    }
    ul{
      li{
        position: relative;
        font-size: 20px;
        font-weight: 600;
        padding-left: 35px;
        margin-bottom: 10px;
        &:last-child{
          margin-bottom: 0;
        }
        i{
          left: 0;
          top: 0;
          font-size: 24px;
          position: absolute;
          color: var(--accent-color);
        }
      }
    }
  }

}