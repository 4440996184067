input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="time"],
input[type="date"],
input[type="search"],
input, textarea {
  margin: 0;
  width: 100%;
  height: 52px;
  font-weight: 400;
  color:var(--heading-color);
  font-size: 20px;
  border-radius: 0;
  background-color: transparent;
  padding: 15px 0 !important;
  border: none!important;
  transition: all 0.4s;
  border-bottom: 1px solid #E7E7E8!important;
}
input:focus, 
textarea:focus{
   border-bottom: 1px solid var(--accent-color)!important;
}
textarea {
  height: 145px;
  resize: none;
  @include max(767px) {
    height: 100px;
  }
}

input::-webkit-input-placeholder, 
textarea::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
    color:    rgba(13, 14, 29, 0.6);
    opacity: 1;
}
input:-moz-placeholder, 
textarea:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
   color:    rgba(13, 14, 29, 0.6);
   opacity:  1;
}
input::-moz-placeholder, 
textarea::-moz-placeholder{ /* Mozilla Firefox 19+ */
   color:    rgba(13, 14, 29, 0.6);
   opacity:  1;
}
input:-ms-input-placeholder, 
textarea:-ms-input-placeholder{ /* Internet Explorer 10-11 */
   color:    rgba(13, 14, 29, 0.6);
   opacity: 1;
}
input::-ms-input-placeholder, 
textarea::-ms-input-placeholder{ /* Microsoft Edge */
   color:    rgba(13, 14, 29, 0.6);
   opacity: 1;
}

input::placeholder, 
textarea::placeholder{ /* Most modern browsers support this now. */
   color:    rgba(13, 14, 29, 0.6);
   opacity: 1;
}

// search header v1
.tekup-header-search-box {
   position: relative;
   input{
      font-size: 40px;
      border: none!important;
      border-radius: 0;
      height: 80px;
      border-bottom: 1px solid #E7E7E8!important;
      margin-bottom: 12px;
      &::placeholder{
         color: var(--heading-color);
      }
      &:focus{
         border-bottom: 1px solid var(--accent-color)!important;
      }     
   }
   
}
button#header-search {
   position: absolute;
   top: 25px;
   right: 0;
}



// footer sebscription
.tekup-subscription{
   position: relative;
   margin-top: 25px;
   input{
      height: 60px;
      border-radius: 0;
      
      padding: 20px!important;
      color: var(--white-color);
      border: 1px solid #252634!important;
      &::placeholder{
         color: rgba(255, 255, 255, 0.4);
      }
      &:focus{
         border: 1px solid var(--accent-color)!important;
      }
   }
   &.two{
      input{
         color: var(--heading-color);
         border: 1px solid #E7E7E8!important;
         &::placeholder{
            color: rgba(13, 14, 29, 0.6);
         }
         &:focus{
            border: 1px solid var(--accent-color)!important;
         }
      }
   }
}
button#tekup-subscription-btn {
   position: absolute;
   top: 14px;
   right: 20px;
   i{
      color: var(--white-color);
      transition: all 0.4s;
      &:hover{
         color: var(--accent-color);
      }
   }
}

.two{
   button#tekup-subscription-btn {
      i{
         color: var(--accent-color);
      }
   }
}