.tekup-icon-list{
  ul{
    li{
      font-weight: 600;
      padding-left: 30px;
      position: relative;
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0;
      }
      i{
        left: 0;
        top: 0;
        position: absolute;
        color: var(--accent-color);
      }
    }
  }
}

.tekup-icon-list{
  &-item{
    gap: 16px;
    display: flex;
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &-icon{
    flex-shrink: 0;
  }
  &-data{
    h5{
      margin-bottom: 10px;
    }
  }
}
// End v2

.tekup-icon-list{
  &-wrap2{
    gap: 70px;
    display: flex;
    flex-wrap: wrap;
    @include max(1199px) {
      gap: 24px;
    }
  }
  &-item2{
    gap: 16px;
    display: flex;
    max-width: 210px;
    align-items: center;
  }
  &-icon2{
    width: 52px;
    flex-shrink: 0;
  }
  &-data2{
    p{
      font-weight: 600;
    }
  }
}
// End v5




/* top scroll */
.progress-wrap {
	position: fixed;
	right: 30px;
	bottom: 30px;
	height: 50px;
	width: 50px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.04);
	z-index: 10000;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap.active-progress {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

.progress-wrap{
    svg path { 
        fill: none; 
    }
}
.progress-wrap svg.progress-circle path {
	stroke: var(--accent-color); /* --- Lijn progres kleur --- */
	stroke-width: 4;
	box-sizing:border-box;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.top-arrow {
    top: 44%;
    left: 60%;
    position: absolute;
    transform: translate(-50%, -50%);
    img{
        width: 55%;
        transform: rotate(-90deg);
    }
}