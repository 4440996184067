.tekup-video{
  &-wrap{
    position: relative;
    &.extra-padding-top{
      margin-top: 130px;
      @include max(991px) {
        margin-top: 100px;
      }
      @include max(767px) {
        margin-top: 80px;
      }
    }
  }
  &-popup{
    left: 50%;
    top: 50%;
    border-radius: 50px;
    position: absolute;
    transition: all 0.4s;
    transform: translate(-50%, -50%);
    @include max(991px) {
      width: 100px;
    }
    @include max(767px) {
      width: 70px;
    }
  }
}



.waves {
  position: absolute;
  width: 210px;
  height: 210px;
  background: var(--white-bg);
  opacity: 0;
  border-radius: 100%;
  left: -35px;
  top: -35px;
  z-index: 0;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
  z-index: -1;
  @include max(991px) {
    width: 160px;
    height: 160px;
    left: -30px;
    top: -30px;
  }
  @include max(767px) {
    width: 130px;
    height: 130px;
  }
  &.wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  &.wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  &.wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
}


@keyframes waves {
  0% {
      -webkit-transform: scale(0.2, 0.2);
      transform: scale(0.2, 0.2);
      opacity: 0;
  }

  50% {
      opacity: 0.9;
  }

  100% {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
      opacity: 0;
  }
}
