.tekup-breadcrumb{
  z-index: 0;
  text-align: center;
  padding: 170px 0;
  position: relative;
  background-size: cover;
  background-position: center center;
  margin-top: 157px;
  &:before {
    content: " ";
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center center;
    background-image: url(../images/breadcrumb/overlay.png);
  }
  @include max(991px) {
    padding: 100px 0 80px;
    margin-top: 90px;
  }
  @include max(767px) {
    padding: 100px 0 80px;
  }
  @include max(479px) {
    margin-top: 75px;
  }
}

h1.post__title{
  margin-bottom: 15px;
  color: var(--white-color);
}

.breadcrumbs{
  ul{
    li{
      line-height: 1;
      z-index: 0;
      padding: 0 18px;
      position: relative;
      display: inline-block;
      color: var(--white-color);
      @include max(767px) {
        font-size: 18px;
      }
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
      &:before {
        content: "\ea6e";
        right: -14px;
        top: 2px;
        z-index: -1;
        position: absolute;
        font-family: 'remixicon';
      }
      &:last-child:before{
        content: none;
      }
      a{
        color: var(--white-color);
      }
    }
  }
}

.tekup-errors{
  &-section{
    padding: 330px 0 130px;
    @include max(991px) {
      padding: 250px 0 100px;
    }
    @include max(767px) {
      padding: 170px 0 80px;
    }
  }
  &-content{
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
    img{
      margin-bottom: 40px;
    }
    h2{
      margin-bottom: 15px;
    }
  }
}