// bootstrap button style

.tekup-default-btn, 
#tekup-default-btn {
  font-size: $button_font_size;
  line-height: $buton_line_height;
  padding: $button_padding;
  border-radius: $buton_border_radius;
  font-weight: $button_font_weight;
  font-family: $button_font_family;
  width: fit-content;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  text-align: center;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow: hidden;
  color: var(--white-color)!important;
  background-color: var(--accent-bg);
  position: relative;
  z-index: 0;
  overflow: hidden;
  position:relative;
  transform-style: preserve-3d;
  i{
    font-size: 22px;
    margin-left: 12px;
    transition: all 0.4s ease-in-out;
  }

  &:hover i{
    transform: translate(42px, -42px);
  }

  &::before{
    background-repeat: no-repeat;
    position: absolute;
    content: "\ea70";
    right: 0;
    top: 0;
    opacity: 1;
    height: 100%;
    font-size: 22px;
    font-family: remixicon !important;
    transform: translate(-65px,65px);
    transition: all 0.2s linear 0s;
  }
  &:hover::before{
    transform: translate(-40px,18px);
  }
  &.tekup-white-btn{
    color: var(--heading-color)!important;
    background-color: var(--white-bg);
    &:before{
      content: "\ea70";
    }
  }
  &.outline-btn-dark{
    padding: 17px 38px;
    color: var(--heading-color)!important;
    border: 1px solid var(--heading-color);
    background-color: transparent !important;
  }
  &.outline-btn-white{
    padding: 17px 38px;
    color: var(--white-color)!important;
    border: 1px solid var(--white-color);
    background-color: transparent !important;
  }
  &.tekup-header-btn{
    padding: 14px 35px;
    &:hover::before{
      transform: translate(-35px,15px);
    }
  }
  &.disabled,
  &:disabled {
    opacity: 1;
  }
  &.d-block {
    width: 100%;
  }
  &.pill {
    border-radius: 50px;
  }
  &.btn-icon {
    padding: 9px 24px;
  }
  &:focus {
    box-shadow: none;
  }
}

