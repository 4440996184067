.tekup-social-icon-box{
  ul{
    li{
      margin-right: 6px;
      display: inline-block;
      &:last-child{
        margin-right: 0;
      }
      a{
        width: 44px;
        height: 44px;
        display: flex;
        font-size: 18px;
        border-radius: 100%;
        align-items: center;
        transition: all 0.4s;
        justify-content: center;
        color: var(--heading-color);
        background: rgba(245, 245, 245, 1);
        &:hover{
          color: var(--white-color);
          background: var(--accent-bg);
        }
        i{
          font-size: 20px;
        }
      }
    }
  }
  &.style-two{
    ul{
      li{
        a{
          color: var(--white-color);
          background: #1F202E;
          &:hover{
            background: var(--accent-bg);
          }
        }
      }
    }
  }
  &.style-three{
    ul{
      li{
        a{
          background: var(--light-bg1);
          &:hover{
            color: var(--white-color);
            background: var(--accent-bg);
          }
        }
      }
    }
  }
  &.style-four{
    ul{
      li{
        a{
          background: var(--white-bg);
          &:hover{
            color: var(--white-color);
            background: var(--dark-bg);
          }
        }
      }
    }
    &.position{
      bottom: 0;
      width: 100%;
      opacity: 0;
      z-index: 2;
      visibility: hidden;
      text-align: center;
      transition: all 0.4s;
      position: absolute;
    }
  }
  &.right{
    text-align: right;
    @include max(767px) {
      text-align: center;
    }
  }
}

.tekup-social-icon{
  margin-top: 24px;
  ul{
    li{
      margin-right: 15px;
      &:last-child{
        margin-right: 0;
      }
      display: inline-block;
      a{
        font-size: 20px;
        transition: all 0.4s;
        color: var(--white-color);
        &:hover{
          color: var(--accent-color);
        }
      }
    }
  }
  &.dark-color{
    ul{
      li{
        a{
          color: var(--heading-color);
          &:hover{
            color: var(--accent-color);
          }
        }
      }
    }
  }
}
.m_bottom{
  @include max(991px) {
    margin-bottom: 40px;
  }
}