.tekup-accordion{
  &-wrap{
    @include max(991px) {
      margin-top: 40px;
    }
  }
  &-item{
    padding: 28px;
    position: relative;
    margin-bottom: 14px;
    transition: all 0.4s;
    background-color: var(--white-bg);
    &:last-child{
      margin-bottom: 0;
    }
    &.open .tekup-accordion-header::before{
      transform: rotate(180deg);
    }
    &.open{
      .tekup-accordion-body{
        display: block;
      }
      .tekup-accordion-header {
        h5, h6{
          color: var(--accent-color);
        }
        &:before{
          color: var(--accent-color);
        }
      }
     
    }
  }
  &-header{
    cursor: pointer;
    position: relative;
    padding-right: 30px;
    h6{
      transition: all 0.4s;
      span{
        margin-right: 5px;
        color: var(--accent-color);
      }
    }
    &::before{
      content: "\ea4e";
      position: absolute;
      right: 0;
      transition: all .4s;
      top: -2px;
      font-size: 30px;
      z-index: 2;
      font-family: remixicon;
      @include max(767px) {
        top: 5px;
      }
    }
   
  }
  &-body{
    display: none;
    padding-top: 15px;
    &.default{
      display: block;
    }

    p{
      font-size: 20px;
      line-height: 30px;
    }
   
  }
}

// v4

.tekup-accordion{
  &-item2{
    padding: 28px 0;
    position: relative;
    transition: all 0.4s;
    
    border-bottom: 1px solid #E7E7E8;
    &:first-child{
      border-top: 1px solid #E7E7E8;
    }
    &.open{
      .tekup-accordion-body2{
        display: block;
      }
      .tekup-accordion-header2 {
        h5{
          color: var(--accent-color);
        }
      }
     
    }
  }
  &-header2{
    cursor: pointer;
    position: relative;
    padding-right: 30px;
    h5{
      margin: 0!important;
      transition: all 0.4s;
      span{
        margin-right: 15px;
        
        i{
          font-size: 24px;
        }
      }
    }
   
  }
  &-body2{
    display: none;
    padding: 0 30px;
    padding-top: 10px;
    &.default{
      display: block;
    }
    p{
      font-size: 20px;
      line-height: 30px;
    }
   
  }
}
// end

.tekup-accordion{
  &-column{
    max-width: 856px;
    margin: 0 auto;
    .tekup-accordion-item{
      border: 1px solid rgba(231, 231, 232, 1);
    }
    .tekup-accordion-body {
      padding-right: 50px;
      @include max(991px) {
        padding-right: 0;
      }
      p{
        font-size: 22px;
        line-height: 32px;
      }
      
    }
  }
}